import React from 'react'
import {
  Button,
  Flex,
  Text,
  Box
} from "@chakra-ui/react";

export default function Pagination({pageIndex,pageCount,canPreviousPage,gotoPage,canNextPage,variant}) {
  if(pageCount <= 1){ return null}
  return (
    <Flex align='center' justify='center' w='100%' position='absolute' bottom='2'>
    <Text fontSize={variant} display={'flex'} align='center' justify='center'>
      <strong>
        Mevcut Sayfa {pageIndex + 1} / {pageCount}
      </strong>{' '}
    </Text>
    <Box marginLeft='12px'>
      <Button size={variant}  onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {'<<'}
      </Button>
      {Array.from({ length: pageCount }, (_, i) => (
        <Button size={variant} key={i} onClick={() => gotoPage(i)} disabled={pageIndex === i}>
          {i + 1}
        </Button>
      ))}
      <Button size={variant} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {'>>'}
      </Button>
    </Box>
  </Flex>
  )
}
