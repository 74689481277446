import {
    Box,
    Flex,
    Text,
    Textarea,
    Button,
    useColorModeValue,
    Grid,
    GridItem,
    Stack,
    Avatar,
    Input,
    Select,
    Image,
    Divider,
    Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton,
    useBreakpointValue
  } from "@chakra-ui/react";
  import { useParams,useHistory } from "react-router-dom/cjs/react-router-dom.min";
  import { getTaskById } from "services/admin";
  import { FaCalendar, FaDownload, FaFile, FaTrash ,FaUser, FaFilePdf,FaFileWord,FaFileExcel,FaRegWindowClose } from "react-icons/fa";
  // import Dropzone from "views/admin/profile/components/Dropzone";

  //carousel
  import Carousel from 'react-multi-carousel';
  import 'react-multi-carousel/lib/styles.css';

  //files ui
  import { Dropzone, FileMosaic } from "@files-ui/react";

  
  import React, { useEffect, useState } from "react";
  
  // Custom components
  import Card from "components/card/Card";
  import toast, { Toaster } from "react-hot-toast";
  import { formatTurkishDate } from "utils";
  import { taskStatusEnum } from "enums";
  import { postTaskComment ,updateTaskContent,handleTaskContentDelete,updateTaskStatus,updateTaskDescription} from "services/admin";
  import { isImageUrl,getFileExtension } from "utils";


  //filepond
  import { FilePond, registerPlugin } from "react-filepond";
  import "filepond/dist/filepond.min.css";
  import FilePondPluginImagePreview from "filepond-plugin-image-preview";
  import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
  import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
  import ConfirmModal from "../components/ConfirmModal";
  import ContentModal from "../components/ContentModal";
  import RejectionModal from "../components/RejectionModal";
  const status = [
    'CREATED',
    'ONGOING',
    'COMPLETED',
    'CANCELED'
  ]
  const responsive = {
    xxl: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    xl: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    l: {
      breakpoint: { max: 1024, min: 754 },
      items: 4
    },
    m: {
      breakpoint: { max: 754, min: 589 },
      items: 3
    },
    s: {
      breakpoint: { max: 589, min: 420 },
      items: 2
    },
    xs: {
      breakpoint: { max: 420, min: 0 },
      items: 1
    }
  };
  const CommentsSection = ({ comment,handleCommentFileClick,handleCommentDelete}) => {
    return (
      <Box marginTop="4">
        <Stack spacing="4">
            <Box  alignItems="center">
              
              <Flex justifyContent={'start'} alignItems={'center'}  gap={'3'} mb={'10px'}>
                  <Avatar size="sm" name={comment.commentorName}/>
                  <Text  fontWeight='bold' >{comment.commentorName} </Text> 
                  <Text fontSize={'xs'} >{formatTurkishDate(comment.createdDate)}</Text>
              </Flex>
             
             
               <Box marginStart={'45px'}>
                  <Text  fontSize="md" marginBottom={'5px'}>{comment.comment}</Text>
                  {comment.contents.length > 0 && isImageUrl(comment.contents[0].fileUrl) ? <Image onClick={() => handleCommentFileClick(comment.contents[0].fileUrl)} width='150px' height='150px' src={comment.contents[0].fileUrl} /> : 
                  comment.contents.length > 0 ? <Text display='flex' cursor='pointer' color='yellow' fontWeight='bold' onClick={() => handleCommentFileClick(comment.contents[0].fileUrl)}><FaFile /> Dosya eki</Text> : null}
                  <Button size="sm" padding={3} marginTop={'8px'} onClick={() => handleCommentDelete(comment.id)}>Yorumu Sil</Button>

               </Box>
             
            </Box>
          <Divider />
        </Stack>
      </Box>
    );
  }


  const EditableText = ({ task }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [description, setDescription] = useState(task.description);
    const [originalDescription,setOriginalDescription] = useState(task.description);
    const textareaRef = React.useRef(null);

    useEffect(() => {
      const handleEscapeKey = (event) => {
        if (event.key === "Escape") {
          setIsEditing(false);
          setDescription(originalDescription);
        }
      };
  
      document.addEventListener("keydown", handleEscapeKey);
  
      return () => {
        document.removeEventListener("keydown", handleEscapeKey);
      };
    }, []);
  
    const handleTextClick = () => {
      setIsEditing(true);
    };
  
    const handleTextareaChange = (e) => {
      setDescription(e.target.value);
    };

    const handleUpdateDescription = async () => {
      let params = {
        taskId : task.id,
        description:description
      }
      let response = await updateTaskDescription(params);
      if(response.status == 200) setIsEditing(false);
    };
  
    const focusTextarea = () => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.selectionStart = textareaRef.current.selectionEnd = textareaRef.current.value.length;
      }
    };
  
    return (
      <>
        {isEditing ? (
          <Flex flexDirection='column' gap={'10px'}>
          <Textarea
            rows="6"
            ref={textareaRef}
            value={description}
            onChange={handleTextareaChange}
            autoFocus
            onFocus={focusTextarea}
            maxLength={500}
          />
          <Button onClick={handleUpdateDescription} width='72px'>Kaydet</Button>
          </Flex>
        ) : (
          <Text
            cursor='pointer'
            padding="24px"
            fontSize="16px"
            fontWeight="700"
            lineHeight="100%"
            onClick={handleTextClick}
          >
            {description}
          </Text>
        )}
      </>
    );
  };
  

  export default function Detail() {
    let history = useHistory();
    if (!window.reactHistory) {
      window.reactHistory = history;
    // attach history object to window object. 
    }
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "white");

    const [task,setTask] = useState(null);
    const [statusValue,setStatusValue] = useState('')
    const [newStatusValue,setNewStatusValue] = useState(false)
    const [isClosed,setIsClosed] = useState(false)
    const [newComment, setNewComment] = React.useState("");

    //file pond
    const [file, setFile] = useState(null);
    const [uploadFileErr,setUploadFileErr] = useState(false)
    const [contentFile,setContentFile] = useState(null);

    //fileUI
    const [imagesFile,setImagesFiles] = useState([])
    const [imagesName,setImagesName] = useState([])

      //filepond
    registerPlugin(FilePondPluginImagePreview,FilePondPluginFileValidateSize);

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isUploadBtnDisabled,setIsUploadBtnDisabled] = useState(false);

    const [isConfirmModalOpen,setConfirmModalOpen] = useState(false);
    const [isContentModalOpen,setContentModalOpen] = useState(false);
    const [isRejectionModalOpen,setRejectionModalOpen] = useState(false);


    const handleStatusChange = (evt) => {
      if(!isClosed){
        setStatusValue(evt.target.value);
        setNewStatusValue(true)
      }else{
        toast.error("Görev kapatıldığı için değişiklik yapılamaz.")
      }
    }


    const handleStatusUpdate = async (rejectionComment) => {

      let params = {
        status:statusValue,
        taskId:task.id
      }
      if(rejectionComment && statusValue == 'CANCELED') {
        setRejectionModalOpen(true);
        console.log(rejectionComment);
        params = {...params,rejectionComment : rejectionComment}
        let response = typeof rejectionComment == 'string' ? await updateTaskStatus(params) : null;
        if(response && response.status == 200){
          toast.success("Görev Durumu Başarıyla Güncellendi")
          fetchTask()
          setNewStatusValue(false)
        }
        return;
      }
      let response = await updateTaskStatus(params);
      if(response.status == 200){
        toast.success("Görev Durumu Başarıyla Güncellendi")
        fetchTask()
        setNewStatusValue(false)
      }
     
    }

    const [selectedImage, setSelectedImage] = useState(null);
    const [activeCommentId,setActiveCommentId] = useState(null);
    const [activeContentId,setActiveContentId] = useState(null);

    const handleFileClick = (fileUrl,isDownload) => {
      if(!isImageUrl(fileUrl) || isDownload){
        window.open(fileUrl, '_blank'); // Opens the file in a new tab for download
        return;
      }
      setSelectedImage(fileUrl);
    };
  
    const handleCloseModal = () => {
      setSelectedImage(null);
    };
    const onChangeComment = (e) => {
      setNewComment(e.target.value);
    }

  

    const onUpload = async () => {
      if(isUploadBtnDisabled) return null;
      if(!contentFile || contentFile.length == 0) return toast.error('Lütfen Dosya Yükleyiniz.')
      let params = {
        taskId:task.id,
        files:contentFile
      }
      setIsUploadBtnDisabled(true);
      let response = await updateTaskContent(params);
      if(response.status == 200) {
        setContentFile(null);
        fetchTask();
        setFile(null); // name
        setContentFile(null); // upload
        setIsUploadBtnDisabled(false);
      }
      if(response.response.status == 400){
        setIsUploadBtnDisabled(false);
      }
    }
    const uploadFunc = (fileitems,err) =>{
      if(err){
        console.log(err);
      }
      const updatedFiles = fileitems.map((fileItem) => fileItem.file);
      const fileByte = updatedFiles.reduce((total,item)=>(total + item.size),0)
      if(fileByte >= 104857600){
        setUploadFileErr(true)
      }else{
        setUploadFileErr(false)
      }
      const files = new DataTransfer();
      updatedFiles.forEach((file) => {
        files.items.add(file);
      });
      const fileList = files.files;
      return {fileList,updatedFiles}
    }
    function handleImageUpload(fileitems,err) {
      const {fileList,updatedFiles} = uploadFunc(fileitems,err)
     
      setFile(updatedFiles); // name
      setContentFile(fileList); // upload
    }

    function uploadImage(fileitems,err){
      const {fileList,updatedFiles} = uploadFunc(fileitems,err)

      setImagesName(fileitems)
      setImagesFiles(fileList)
    }
    const removeFile = (id) => {
      setImagesName(imagesName.filter((x) => x.id !== id));
    };
 
    const handleCommentFileClick = (fileUrl) =>{
      if(!isImageUrl(fileUrl)){
        window.open(fileUrl, '_blank'); // Opens the file in a new tab for download
        return;
      }
      setSelectedImage(fileUrl)
    }

    const handleCommentDelete = async (commentId) =>{
      setActiveCommentId(commentId);
      setConfirmModalOpen(true);
    }

    const handleCloseConfirmModal = () => {
      setActiveCommentId(null);
      setConfirmModalOpen(false);
    }

    const handleContentDelete = async (contentId) =>{
      setActiveContentId(contentId);
      setContentModalOpen(true);
    }

    const handleCloseContentModal = () => {
      setActiveCommentId(null);
      setContentModalOpen(false);
    }

    const handleCloseRejectionModal = () => {
      setRejectionModalOpen(false);
    }

    useEffect(()=>{
      if(uploadFileErr){
        toast.error('Toplam Dosya Boyutu 100MB den Fazla Olamaz')
      }
    },[uploadFileErr])

    const postComment = async () => {
      if(isButtonDisabled) return null;
      if(newComment.length <= 0) return toast.error('Yorum Alanı Boş Bırakılamaz')
      let params = {
        taskId: task.id,
        comment: newComment.length ? newComment : 'Dosya Yüklendi..'
      }
      setIsButtonDisabled(true); // Disable the button
      try {
        let response = await postTaskComment(params,imagesFile);
        if(response.status == 200) {
          setNewComment("");
          fetchTask();
          setImagesFiles([])
          setImagesName([])
        }
      } catch (e) {
        console.log("worked",e,"asd")
      }
      finally {
        setIsButtonDisabled(false); // Re-enable the button
      }
    }

    const templateColumns = useBreakpointValue({
      base: 'repeat(1, 1fr)',
      sm: 'repeat(1,1fr)',
      lg:'repeat(1, 1fr)',
      md:'repeat(1, 1fr)',
    });

    const templateColumnsContent = useBreakpointValue({
      base: 'repeat(2, 1fr)',
      sm: 'repeat(2,1fr)',
      lg:'repeat(6, 1fr)',
      md:'repeat(6, 1fr)',
    });

    const { id } = useParams()

    const fetchTask = async () => {
     try{
      let response =  await getTaskById(id);
      setTask(response.data);
      setStatusValue(response.data.status)
      if(response.data.status == 'CANCELED'){
        setIsClosed(true)
        setIsButtonDisabled(true)
        setIsUploadBtnDisabled(true)
      } 
     }catch(err){
      console.error(err);
     }
    }
    console.log(task);
    const renderIcon = (fileUrl) => {
      const extension = getFileExtension(fileUrl);
      switch (extension) {
        case 'pdf':
          return <FaFilePdf size='125' onClick={() => handleFileClick(fileUrl)} />;
        case 'xls':
        case 'xlsx':
          return <FaFileExcel size='125' onClick={() => handleFileClick(fileUrl)} />;
        case 'doc':
        case 'docx':
          return <FaFileWord size='125' onClick={() => handleFileClick(fileUrl)} />;
        default:
          return <FaFile size='125' onClick={() => handleFileClick(fileUrl)} />;
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        postComment();
      }
    };
    
    useEffect(() =>{
      fetchTask();
    }, [id])
    if(!task) return (<>LOADİNG...</>)
    return (
      <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card
          direction="column"
          w="100%"
          mb="20px"
        >
          <Flex justify={'space-between'} flexDirection={{sm:'column',md:'row'}} >
            <Text
              color={textColor}
              padding="10px"
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
              order={{sm:'1',md:'0'}}
              maxWidth={'700px'}
              wordBreak={'break-word'}
            >
              {task.title}
              <Box borderBottom={`1px solid ${textColor}`} margin={'5px 0px'}></Box>
              <Text alignItems='center' fontSize='12px'>
              {isClosed &&   <Flex color={'red'} gap='6px' alignItems='center'><FaRegWindowClose /> Görevde herhangi bir güncelleme yapılamaz. </Flex>}
                <Flex gap='6px' alignItems='center'><FaCalendar /> Oluşturma Tarihi : {formatTurkishDate(task.createdDate)} </Flex>
                <Flex gap='6px' alignItems='center'><FaCalendar/> Son Teslim : {task.dueDate}</Flex>
                <Flex gap='6px' alignItems='center'><FaUser/> Görev atayan : {task.assignorName}</Flex>
                <Flex gap='6px' alignItems='center'><FaUser/> Görev atanan : {task.assigneName}</Flex>
              </Text>
            </Text>
            <Flex justifyContent={{md:'flex-end', sm:'start'}} order={{sm:'0',md:'1'}} gap={'5px'} >
              <Select className="city-select-wrapper" maxWidth={'200px'} value={statusValue} onChange={handleStatusChange} >
                  
                  {
                    status.map((e,i)=>(
                      <option key={i} value={e} >{taskStatusEnum[e]}</option>
                    ))
                  }
              </Select>
              <Button whiteSpace='break' disabled={newStatusValue ? false : true} paddingX={10} onClick={handleStatusUpdate}>{newStatusValue ? 'Güncelle' : 'Seçim Yapınız'}</Button>
            </Flex>
          </Flex>
        </Card>
        <Grid templateColumns={templateColumns}>
          <Card >
            {
              task.status == 'CANCELED' ? 
              <>
                <Text 
                color={textColor}
                padding="10px"
                fontSize="18px"
                fontWeight="700"
                lineHeight="100%">İptal Notu</Text>
                 <Text
                  cursor='pointer'
                  padding="24px"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  {task.rejectionComment}
                </Text>
              </>
              :
              null
            }
            <Text
              color={textColor}
              padding="10px"
              fontSize="18px"
              fontWeight="700"
              lineHeight="100%"
            >
              Açıklama
            </Text>
            {/* <Text
              cursor='pointer'
              color={textColor}
              padding="24px"
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
            >
              {task.description}
            </Text> */}
            <EditableText task={task} />
            <Flex flexDirection='column'>
            <Text
              color={textColor}
              padding="10px"
              fontSize="18px"
              fontWeight="700"
              lineHeight="100%"
            >
              Dosya Ekleri
            </Text>
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              infinite ={false}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              responsive={responsive}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
              
            >
              {task.contents.map(content => (
                  <Box w='156px'border={`0.2px solid gray`} p={'10px'} borderRadius={'10px'}>
                    {isImageUrl(content.fileUrl) ? 
                    <Image objectFit='cover' height='125px' onClick={() => handleFileClick(content.fileUrl,false)} key={content.id} src={content.fileUrl} alt={content.altText} />
                    : <Flex textAlign='center'>{renderIcon(content.fileUrl)}</Flex>}
                      
                    <Flex alignItems='center' justifyContent='space-between'   position='relative' bottom='0' mt={'10px'}>
                        <FaDownload onClick={() => handleFileClick(content.fileUrl,true)}/>
                        <FaTrash cursor='pointer'  onClick={() => handleContentDelete(content.id)} color="white" />
                    </Flex>
                  </Box>
              ))}
            </Carousel>
            <FilePond
              files={file}
              maxTotalFileSize='100MB'
              onupdatefiles={handleImageUpload}
              allowMultiple
              maxFiles={5}
              labelIdle='Sürükle Bırak ya da <span class="filepond--label-action">Dosyalardan Seç</span>'
            />
          
            <Modal size='5xl' isOpen={!!selectedImage} onClose={handleCloseModal}>
              <ModalOverlay />
              <ModalContent padding='16'>
                <ModalCloseButton />
                <ModalBody>
                  {selectedImage && isImageUrl(selectedImage) &&  <Image src={selectedImage} alt="Full Screen" /> }
                </ModalBody>
              </ModalContent>
            </Modal>
            <Button onClick={onUpload} borderRadius='15' display={'inline-block'} px={'30px'} margin={'auto'} isDisabled={isUploadBtnDisabled} >Yükle</Button>
            <Text paddingLeft='8px' paddingTop='32px' alignItems='center' fontSize='14px'><Flex gap='6px' alignItems='center'><FaCalendar /> Son Güncelleme Tarihi : {formatTurkishDate(task.lastModifiedDate)} </Flex></Text>         
          </Flex>
          </Card>
        </Grid>
        <Grid templateColumns={templateColumns} gap='24px'>
          <Card  my={"15px"}>
          <Text
              color={textColor}
              padding="10px"
              fontSize="18px"
              fontWeight="700"
              lineHeight="100%"
              borderBottom={`1px solid ${textColor}`}
            >
              Yorumlar
            </Text>
            {task.comments.map((comment,i) => (
              <CommentsSection key={i} comment={comment}  handleCommentFileClick={handleCommentFileClick} handleCommentDelete={handleCommentDelete} />
            ))}
          <Dropzone
              style={{margin:'20px 0px'}}
              minHeight="80px"
              onChange={uploadImage}
              value={imagesName}
              label='Sürükle Bırak ya da Dosyalardan Seç'
              //accept="image/*"
              maxFiles={1}
              maxFileSize={100 * 1024*1024}
              header={false}
              // headerConfig={{ customMessage: "" }}
              footer={false}
            >
              {
                imagesName && 
                imagesName.map((file) => (
                  <FileMosaic key={file.id} {...file}  onDelete={removeFile}  preview valid={undefined} />
                ))
              }
            </Dropzone>
            <Input
              type='text'
              background="transparent"
              height='36px'
              border={`1px solid ${textColor}`}
              color={textColor}
              required
              name="comment"
              placeholder="Notunuz"
              onChange={onChangeComment}
              value={newComment}
              onKeyDown={handleKeyPress}
              rows={1}

            />


            <Button onClick={postComment}  marginStart={"auto"} display={"block"} marginTop={"10px"} isDisabled={isButtonDisabled}>
              Gönder
            </Button>
            
          </Card>
        </Grid>        
      </Box>
      <Toaster  position="top-right" />
      <ConfirmModal isOpen={isConfirmModalOpen} 
      handleCloseModal={handleCloseConfirmModal} 
      commentId={activeCommentId} 
      fetchTask={fetchTask}  />
      <ContentModal isOpen={isContentModalOpen} 
      handleCloseModal={handleCloseContentModal} 
      contentId={activeContentId} 
      fetchTask={fetchTask}  />
      <RejectionModal
      isOpen={isRejectionModalOpen}
      handleCloseModal={handleCloseRejectionModal}
      updateStatus={handleStatusUpdate} />
      </>
    );
  }
  