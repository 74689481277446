import axiosInstance from "./interceptors";
import toast from "react-hot-toast";

async function getNotifications() {
  const token = localStorage.getItem('token');
  if(!token) {
    return;
  }
  try {
    const response = await axiosInstance.get(process.env.REACT_APP_API_USER_URL + '/api/user/notification?page=0&limit=5', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function sendReadInfo(id) {
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.patch(process.env.REACT_APP_API_USER_URL + `/api/user/notification?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function bulkRead(list) {
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.post(process.env.REACT_APP_API_USER_URL + `/api/user/notification-bulk`,list, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

export { getNotifications , sendReadInfo , bulkRead}