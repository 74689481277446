import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Select,
  FormLabel,
  useColorModeValue,
  useDisclosure,
  Modal,
  Box,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  background,
  filter,
} from "@chakra-ui/react";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useBranchStore } from "stores/branchStore";
import { Checkbox } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import Pagination from "components/Pagination";
// import InputField from "components/fields/InputField";
import toast, { Toaster } from "react-hot-toast";
import { MultiSelect } from "chakra-multiselect";
import "../../../../assets/css/multiselect.css";

import { titleEnum, roleEnum, statusEnum } from "enums";
// import { createEmployee } from "services/branchManager";
import { registerEmployee } from "services/admin";
import { getEmployees } from "services/branchManager";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useCityStore } from "stores/cityStore";
import { getCities } from "services/admin";

const validationSchema = Yup.object({
  name: Yup.string().required("İsim Soyisim zorunludur"),
  email: Yup.string()
    .email("Geçersiz email adresi")
    .required("Email zorunludur"),
  password: Yup.string()
    .min(4, "Şifre en az 4 karakter olmalıdır")
    .required("Şifre zorunludur"),
  address: Yup.string().required("Adres zorunludur"),
  phoneNumber: Yup.string().required("Telefon No zorunludur")
  .matches(/^\d+$/, "Telefon No yalnızca rakamlardan oluşmalıdır")
  .max(10, "Telefon No en fazla 10 karakter olmalıdır"),
  userRole: Yup.string().required("Rol zorunludur"),
  branchId: Yup.string().required("Şube zorunludur"),
  cityName: Yup.string().required("Şehir zorunludur"),
});

// Assets
export default function EmployeeTable(props) {
  const { columnsData, tableData, fetchEmployees } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const branches = useBranchStore((state) => state.branches);
  const [branchOptions, setBranchOptions] = React.useState([]);
  const [cityOptions, setCityOptions] = React.useState([]);
  const updateCity = useCityStore(store => store.setCity)
  const cityData = useCityStore((state) => state.city)
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);

  const openModal1 = () => setIsOpenModal1(true);
  const closeModal1 = () => setIsOpenModal1(false);

  const openModal2 = () => setIsOpenModal2(true);
  const closeModal2 = () => setIsOpenModal2(false);

  const [userData, setUserData] = React.useState({
    name: "",
    email: "",
    password: "",
    address: "",
    phoneNumber: "",
  });
  const [cityValue, setCityValue] = React.useState([]);
  const [branchValue, setBranchValue] = React.useState([]);
  const [role, setSelectedRole] = React.useState("ADMIN");
  const [branch, setBranch] = React.useState("-1");
  const [userStatus, setUserStatus] = useState(false);


  async function fetchCity(){
    let response = await getCities();
    updateCity({cities:response.data.sort((a,b) => a.name.localeCompare(b.name))})
  }
  React.useEffect(() => {
    fetchCity()

  }, []);

  React.useEffect(() => {
    if(cityData.cities.length > 0) {
      setCityOptions(
        cityData.cities.map((city,i) => ({
          label: city.name,
          value: city.name,
        }))
      );
    }
  }, [cityData]);

  React.useEffect(() => {
    setBranchValue([])
    const selectedCity = cityData.cities.filter(e => e.name == cityValue)
    
    if(selectedCity.length !== 0){
      setBranchOptions(
        selectedCity[0].branches.map((branch,i) => ({
          label: branch.name,
          value: branch.name,
        }))
      );
    }
  }, [cityValue]);

  const openEmployeeModal = () => {
    // setSelectedRowData(rowData);
    openModal1();
  };
  const openFilterModal2 = () => {
    // setSelectedRowData(rowData);
    openModal2();
  };

  const submitForm = async (values) => {
    try {
      let response = await registerEmployee(values);
      if (response.status == 200) {
        toast.success("Personel ekleme/düzenleme işlemi tamamlandı.");
        tableData.push(userData);
        setSelectedRole("ADMIN");
        setBranch("-1");
        setUserData({
          name: "",
          email: "",
          address: "",
          phoneNumber: "",
          password: "",
        });
        closeModal1();
        fetchEmployees();
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const onChangeHandler = (evt) => {
    const { name, value } = evt.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const filterBranches = async () => {
    if(branchOptions.length > 0 && branchValue.length == 0){
      toast.error("Şube seçimi yapınız");
    }else if(cityValue.length !== 0 && branchOptions.length == 0){
      toast.error("Aktif şube olmadığı için filtreleme yapılamadı.");
    }
    else{
      let status = userStatus ? ["ACTIVE", "PASSIVE"] : undefined;

    let branchIds = [];
    branchValue.map((val) => {
      let branch = branches.find((branch) => branch.name == val);
      branchIds.push(branch.id);
    });
    let response = await fetchEmployees(branchIds, [], status);
    toast.success("Çalışanlar Filtrelendi");
    }
  };
  const resetFilter = async () => {
    setCityValue([])
    setBranchValue([])
    setBranchOptions([])
    setUserStatus(false)
    await fetchEmployees()
    toast.success("Filtrelemeler Sıfırlandı");
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    gotoPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    initialState,
  } = tableInstance;
  initialState.pageSize = 8;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgColor = useColorModeValue("gray.500", "whiteAlpha.900");
  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Personel Tablosu
          </Text>
          <Flex align="center">
            <Button
              marginLeft="8px"
              alignSelf="flex-end"
              onClick={() => openFilterModal2()}
            >
              Filtrele
            </Button>
            <Button
              marginLeft="8px"
              alignSelf="flex-end"
              onClick={() => openEmployeeModal()}
            >
              Çalışan Ekle
            </Button>
          </Flex>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      textTransform="capitalize"
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaCaretDown />
                        ) : (
                          <FaCaretUp />
                        )
                      ) : (
                        // Render a neutral icon if not sorted
                        <FaCaretUp />
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    data = (
                      <Flex align="center">
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                    if (cell.column.Header === "İşlemler") {
                      data = (
                        <Flex align="center">
                          {cell.value && (
                            <Button
                              w="80px"
                              minW="80px"
                              variant="brand"
                              fontWeight="500"
                              onClick={() => openEmployeeModal(row.original)}
                            >
                              Düzenle
                            </Button>
                          )}
                        </Flex>
                      );
                    } else if (cell.column.Header === "Ünvan") {
                      data = (
                        <Flex align="center">
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {titleEnum[cell.value]}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "Çalışan Statü") {
                      data = (
                        <Flex align="center">
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {statusEnum[cell.value]}
                          </Text>
                        </Flex>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Toaster position="top-right" />
        <Pagination
          variant='sm'
          gotoPage={gotoPage}
          pageIndex={pageIndex}
          pageCount={pageCount}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </Card>
      <Modal
        isOpen={isOpenModal1}
        onClose={closeModal1}
        isCentered
        backdropFilter="blur(10px) hue-rotate(90deg)"
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader bg={borderColor}>{userData.name ? userData.name + 'Düzenle' : 'Çalışan Ekle'}</ModalHeader> */}
          <ModalHeader bg={borderColor}>Personel Ekle/Düzenle</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              name: userData.name || "",
              email: userData.email || "",
              password: userData.password || "",
              address: userData.address || "",
              phoneNumber: userData.phoneNumber || "",
              userRole: "",
              branchId: "",
              cityName: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              submitForm(values);
            }}

          >
            {({ handleChange, handleBlur, values, submitForm }) => (
              <Form>
                <ModalBody bg={borderColor}>
                  <FormLabel padding={'8px'}>İsim Soyisim</FormLabel>
                  <Field
                    name="name"
                    as={Input}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    background="white"
                    color="black"
                  />
                  <ErrorMessage render={msg => <Box paddingLeft={'8px'} color="red">{msg}</Box>} name="name" component="div" />

                  <FormLabel padding={'8px'}>Email</FormLabel>
                  <Field
                    name="email"
                    as={Input}
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    background="white"
                    color="black"
                  />
                  <ErrorMessage render={msg => <Box paddingLeft={'8px'} color="red">{msg}</Box>} name="email" component="div" />

                  <FormLabel padding={'8px'}>Şifre</FormLabel>
                  <Field
                    name="password"
                    as={Input}
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    background="white"
                    color="black"
                  />
                  <ErrorMessage render={msg => <Box paddingLeft={'8px'} color="red">{msg}</Box>} name="password" component="div" />

                  <FormLabel padding={'8px'}>Adres</FormLabel>
                  <Field
                    name="address"
                    as={Input}
                    type="textarea"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    background="white"
                    color="black"
                  />
                  <ErrorMessage render={msg => <Box paddingLeft={'8px'} color="red">{msg}</Box>} name="address" component="div" />

                  <FormLabel padding={'8px'}>Telefon No</FormLabel>
                  <Field
                    name="phoneNumber"
                    as={Input}
                    type="phone"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    background="white"
                    color="black"
                  />
                  <ErrorMessage render={msg => <Box paddingLeft={'8px'} color="red">{msg}</Box>} name="phoneNumber" component="div" />

                  <FormLabel
                    padding={'8px'}
                  >
                    Rol
                  </FormLabel>
                  <Field
                    as={Select}
                    name="userRole"
                    value={values.userRole}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    background="white"
                    color="black"
                  >
                    <option disabled value="">
                      Rol Seçiniz
                    </option>
                    {roleEnum.map((role, index) => (
                      <option key={index} value={role.value}>
                        {role.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage render={msg => <Box paddingLeft={'8px'} color="red">{msg}</Box>} name="userRole" component="div" />

                  <FormLabel
                  padding={'8px'}
                  >
                    Şehir
                  </FormLabel>
                  <Field
                    as={Select}
                    name="cityName"
                    value={values.cityName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    background="white"
                    color="black"
                  >
                    <option value="" disabled>
                      Şehir Seçiniz
                    </option>
                    {cityData.cities.map((city, index) => (
                      <option key={index} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage render={msg => <Box paddingLeft={'8px'} color="red">{msg}</Box>} name="cityName" component="div" />
                  {
                    values.cityName !== '' && cityData.cities.find(e => e.name == values.cityName).branches.length > 0 ?

                   <>
                     <FormLabel
                    padding={'8px'}
                    >
                      Şube
                    </FormLabel>
                    <Field
                      as={Select}
                      name="branchId"
                      value={values.branchId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      background="white"
                      color="black"
                    >
                      <option value="" disabled>
                        Şube Seçiniz
                      </option>
                      {cityData.cities.find(e => e.name == values.cityName).branches.map((branch, index) => (
                        <option key={index} value={branch.id}>
                          {branch.name}
                        </option>
                      ))
                      }
                    </Field>
                    <ErrorMessage render={msg => <Box paddingLeft={'8px'} color="red">{msg}</Box>} name="branchId" component="div" />
                   </>
                   :
                   values.cityName &&
                   <Text color='red' mt='15px' ps='10px'>Bu şehirde aktif şube bulunmamaktadır.</Text>
                  }
                 
                </ModalBody>

                <ModalFooter bg={borderColor}>
                  <Button colorScheme="green" mr={3} onClick={submitForm}>
                    Kaydet
                  </Button>
                  <Button colorScheme="blue" mr={3} onClick={closeModal1}>
                    Kapat
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenModal2}
        onClose={closeModal2}
        isCentered
        backdropFilter="blur(10px) hue-rotate(90deg)"
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader bg={borderColor}>{userData.name ? userData.name + 'Düzenle' : 'Çalışan Ekle'}</ModalHeader> */}
          <ModalHeader bg={borderColor}>Filtrele</ModalHeader>
          <ModalCloseButton />
          <ModalBody bg={borderColor}>
            <Checkbox
              onChange={() => setUserStatus((status) => !status)}
              mb={"10px"}
              defaultChecked = {userStatus}
            >
              Aktif olmayan personel kayıtlarını göster
            </Checkbox>
            <MultiSelect
              listProps={{
                background: bgColor,
                padding: "16px",
                borderRadius: "8px",
                textColor: "black",
                height:"250px",
                overflowY:"scroll",
                zIndex:'99'
               
              }}
              single
              controlProps={{
                marginBottom:'10px',

              }}
              id="branch-multiselect"
              options={cityOptions}
              value={cityValue}
              label="Şehir Seçimi Yapınız"
              onChange={setCityValue}
            />
            {
              branchOptions.length > 0 ?
              <MultiSelect
              listProps={{
                background: bgColor,
                padding: "16px",
                borderRadius: "8px",
                textColor: "black",
                height:"250px",
                overflowY:"scroll",
                zIndex:'99'
              }}
              id="branch-multiselect"
              options={branchOptions}
              value={branchValue}
              label="Şube Seçimi Yapınız"
              onChange={setBranchValue}
            />
            : cityValue.length !== 0 &&
            <Text color='red' textAlign='center'>Bu şehirde aktif şube bulunmamaktadır.</Text>
            }
            
          </ModalBody>
          <ModalFooter bg={borderColor}>
            <Button colorScheme="green" mr={3} onClick={filterBranches}>
              Filtrele
            </Button>
            <Button colorScheme="red" mr={3} onClick={resetFilter}>
              Sıfırla
            </Button>
            <Button colorScheme="blue" mr={3} onClick={closeModal2}>
              Kapat
            </Button>
            {/* Add other modal actions as needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
