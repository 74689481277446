// Chakra imports
import {
  Flex,
  FormLabel,
  Input,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React from "react";

export default function Default(props) {
  const {
    id,
    label,
    extra,
    placeholder,
    type,
    mb,
    value,
    onChange,
    maxLength,
    maxLengthWarnText,
    toggleTextArea,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [isFocused, setIsFocused] = React.useState(false);
  const textareaRef = React.useRef(null); // Ref for textarea


  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


  return (
    <Flex direction="column" mb={mb ? mb : "30px"}>
      <FormLabel
        display="flex"
        ms="10px"
        htmlFor={id}
        fontSize="sm"
        color={textColorPrimary}
        marginTop={rest.labelmargin}
        fontWeight="bold"
        _hover={{ cursor: "pointer" }}
      >
        {label}
        <Text fontSize="sm" fontWeight="400" ms="2px">
          {extra}
        </Text>
      </FormLabel>
      <Textarea
        {...rest}
        type={type}
        id={id}
        fontWeight="500"
        variant="secondary"
        value={value} // Add value prop
        onChange={onChange} // Add onChange prop
        maxLength={maxLength}
        multiple={rest.multiple}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {placeholder}
      </Textarea>

      {maxLengthWarnText && isFocused && (
        <span className="fs-12 mb-8">{maxLengthWarnText}</span>
      )}
    </Flex>
  );
}
