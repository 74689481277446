import { create } from 'zustand'

const useBranchStore = create((set) => {
  const storedBranch = JSON.parse(localStorage.getItem('branches')) || {
    branches:[]
  };

  return {
    branches: storedBranch,
    setBranch: (branches) => {
      localStorage.setItem('branches', JSON.stringify(branches));
      set(() => ({ branches }));
    },
  };
});

export {useBranchStore};