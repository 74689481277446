import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdSchool,
  MdInventory2,
  MdPersonAddAlt1,
  MdAddTask ,
  MdAccessTime,
  MdOutlineTimerOff,
  MdInsertPhoto
} from "react-icons/md";

import { CiCoffeeBean } from "react-icons/ci";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import InventoryManagement from "views/admin/dataTables/inventoryManagement";
import Detail from 'views/admin/taskManagement/detail/Detail';
import TaskManagement from 'views/admin/taskManagement/TaskManagement'
import BannerManagement from "views/admin/bannerManagement/BannerManagement";

// import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import EmployeeManagement from "views/admin/employeeManagement/employeeManagement";
import ResetPassword from "views/auth/resetPassword"
import ShiftManagement from "views/admin/shiftManagement/shiftManagement";
import AnnualManagement from "views/admin/annualManagement/AnnualManagement";
import ProductManagement from "views/admin/productManagement/ProductManagement";

const routes = [
  {
    name: "Yönetim Paneli",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  // {
  //   name: "Education Place",
  //   layout: "/admin",
  //   path: "/education-place",
  //   icon: (
  //     <Icon
  //       as={MdSchool}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  {
    name: "Personel Yönetimi",
    layout: "/admin",
    path: "/employee-management",
    icon: (
      <Icon
        as={MdPersonAddAlt1}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: EmployeeManagement,
    secondary: true,
  },
  {
    name: "Görev Yönetimi",
    layout: "/admin",
    path: "/task-management",
    icon: (
      <Icon
        as={MdAddTask}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: TaskManagement,
    secondary: true,
  },
  // {
  //   name: "Shift Yönetimi",
  //   layout: "/admin",
  //   path: "/shift-management",
  //   icon: (
  //     <Icon
  //       as={MdAccessTime}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: ShiftManagement,
  //   secondary: true,
  // },
  // {
  //   name: "Izin Yönetimi",
  //   layout: "/admin",
  //   path: "/annual-management",
  //   icon: (
  //     <Icon
  //       as={MdOutlineTimerOff}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: AnnualManagement,
  //   secondary: true,
  // },
  // {
  //   name: "Ürün Yönetimi",
  //   layout: "/admin",
  //   path: "/product-management",
  //   icon: (
  //     <Icon
  //       as={CiCoffeeBean}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: ProductManagement,
  //   secondary: true,
  // },
  // // {
  //   name: "Envanter Yönetimi",
  //   layout: "/admin",
  //   path: "/inventory-management",
  //   icon: (
  //     <Icon
  //       as={MdInventory2}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: InventoryManagement,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  {
    name: "Detay",
    layout: "/admin",
    path: "/task-detail/:id",
    component: Detail,
    secondary: true,
    detail:true
  },
  {
    name: "Giriş Yap",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  {
    name: "Şifre Sıfırla",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ResetPassword,
    secondary:true
  },
  {
    name: "Görsel Yönetimi",
    layout: "/admin",
    path: "/banner-management",
    icon: <Icon as={MdInsertPhoto} width='20px' height='20px' color='inherit' />,
    component: BannerManagement,
    secondary:true
  },
  // {
  //   name: "-",
  //   layout: "/admin",
  //   path: "/task-detail",
  //   component: Detail,
  //   secondary: true,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export default routes;
