import axios from 'axios';
import toast from "react-hot-toast";

// Create an Axios instance
const axiosInstance = axios.create();

// Request interceptor
axiosInstance.interceptors.response.use(
    async (config) => {
        return config;
    },
    async (error) => {
            const validationToken = localStorage.getItem('token');
            if(!validationToken){
              toast.error("Lütfen Giriş Yapınız !");
              console.log("test")
              setTimeout(() => {
                window.location.href = "/";
              }, 2000);
              return;
            }
          // Fetch the validation token
          const response = await axios.get(process.env.REACT_APP_API_USER_URL + '/api/auth/validate',{
            headers: {
              Authorization: `Bearer ${validationToken}`,
            },
          });
          if(!response.data.validated){
            localStorage.removeItem('token');
            toast.error("Lütfen Giriş Yapınız !")
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
            return;
          }
        // Handle request error
        return Promise.reject(error);
    }
);

export default axiosInstance;
