// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Chakra-Date Imports
import { RangeDatepicker } from "chakra-dayzed-datepicker";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useUserStore } from "stores/userStore";
import { titleEnum } from "enums";
import { handleLogout } from "services/admin";
// Assets
import navImage from "assets/img/layout/Navbar.png";
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import routes from "routes.js";
import { ThemeEditor } from "./ThemeEditor";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { createAnnualLeave } from "services/annual";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { getNotifications , sendReadInfo , bulkRead } from "services/notifications";

export default function HeaderLinks(props) {
  const { secondary } = props;
  let history = useHistory();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
  const user = useUserStore((state) => state.user);
  const [notifications,setNotifications] = React.useState([]);
  const signOut = async () => {
    history.push("/auth/sign-in");
    await handleLogout();
  };
  const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);

  async function handleAnnual() {
    const param = {
      day: (selectedDates[1] - selectedDates[0]) / (1000 * 3600 * 24),
      startDate: format(selectedDates[0], "yyyy-MM-dd"),
      endDate: format(selectedDates[1], "yyyy-MM-dd"),
    };
    if (selectedDates.length == 2) {
      await createAnnualLeave(param);
      toast.success("Başarılı");
    }
  }
  const fetchNotifications = async () => {
    console.log(user);
    const response = typeof user.id == 'number' ? await getNotifications() : null;
    if(response && response.status == 200 && response.data.types.length){
      const filteredData = response.data.types.filter(notify => !notify.isRead);
      console.log(filteredData);
      setNotifications(filteredData);
    }
  }
  const determineRoute = (type) => {
    switch(type){
      case "TASK":
        return "/admin/task-detail/"
    }
  }
  const sentReadInfo = async (id) => {
    const response = await sendReadInfo(id);
  }
  const readAll = async () => {
    let idList = [];
    notifications.filter((notify) => !notify.isRead).map(notify => {
      idList.push(notify.id);
    })
    const response = await bulkRead(idList);
    if(response.status == 200){
      toast.success("Tüm Bildirimler Okundu !")
    }
  }
  const goToPage = (notification) => {
    const route = determineRoute(notification.type);
    sentReadInfo(notification.id);
    history.push(route + notification.relatedId);
  }
  React.useEffect(() =>{
    fetchNotifications()
  } ,[])
  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      {/* <SearchBar
        mb={secondary ? { base: "10px", md: "unset" } : "unset"}
        me="10px"
        borderRadius="30px"
      /> */}
      <Flex
        bg={ethBg}
        display={secondary ? "flex" : "none"}
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="30px"
          me="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaUser} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          <Text as="span" display={{ base: "none", md: "unset", sm: "block" }}>
            {" "}
            {titleEnum[user.role]}
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive routes={routes} />
      <Menu>
        <MenuButton p="0px">
          <Icon
            mt="6px"
            as={MdNotificationsNone}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: "30px", md: "unset" }}
          minW={{ base: "unset", md: "400px", xl: "450px" }}
          maxW={{ base: "360px", md: "unset" }}
        >
          <Flex jusitfy="space-between" w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Bildirimler
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              onClick={() => readAll()}
              ms="auto"
              cursor="pointer"
              pointerEvents={!notifications.length ? 'none' : 'auto'}
            >
              Tümünü Okundu Olarak İşaretle
            </Text>
          </Flex>
          <Flex flexDirection="column">
            {notifications.length == 0 ? <Text>Bildiriminiz Bulunmamaktadır.</Text>  : notifications.map((notification) => (
              <MenuItem
                _hover={{ bg: "rgba(0,0,0.35,0.35)" }}
                _focus={{ bg: "none" }}
                px="0"
                borderRadius="8px"
                mb="10px"
                onClick={() => goToPage(notification)}
                key={notification.id}
              >
                <ItemContent info={notification.content} aName="Alicia" />
              </MenuItem>
            ))}
          </Flex>
        </MenuList>
      </Menu>

      {/* <Menu>
        <MenuButton p="0px">
          <Icon
            as={MdInfoOutline}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          me={{ base: "30px", md: "unset" }}
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          minW={{ base: "unset" }}
          maxW={{ base: "360px", md: "unset" }}
        >
          <Flex flexDirection="column">
            <Text
              color={textColor}
              fontSize="18px"
              fontWeight="700"
              lineHeight="100%"
            >
              İzin Talep Formu
            </Text>
            <RangeDatepicker
              propsConfigs={{
                inputProps: {
                  color: textColor,
                  borderColor: textColor,
                  marginY: "10px",
                },
              }}
              selectedDates={selectedDates}
              onDateChange={setSelectedDates}
            />
            <MenuItem
              borderRadius={"10px"}
              display={"flex"}
              justifyContent={"center"}
              bgColor={"green.400"}
              _hover={{ background: "green.600" }}
              fontWeight={"bold"}
              onClick={() => handleAnnual()}
            >
              Kaydet
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}

      <ThemeEditor navbarIcon={navbarIcon} />

      <Menu>
        <MenuButton>
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={user.name}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {user.name}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {/* <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Profil Ayarları</Text>
            </MenuItem> */}
            {/* <Menu>
              <MenuButton px="14px">                
                <Text display='flex' fontSize='sm'>İzin Talebi</Text>
              </MenuButton>
              <MenuList
                boxShadow={shadow}
                p="20px"
                me={{ base: "30px", md: "unset" }}
                borderRadius="20px"
                bg={menuBg}
                border="none"
                mt="22px"
                minW={{ base: "unset" }}
                maxW={{ base: "360px", md: "unset" }}
              >
                <Flex flexDirection="column">
                  <Text
                    color={textColor}
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="100%"
                  >
                    İzin Talep Formu
                  </Text>
                  <RangeDatepicker
                    propsConfigs={{
                      inputProps: {
                        color: textColor,
                        borderColor: textColor,
                        marginY: "10px",
                      },
                    }}
                    selectedDates={selectedDates}
                    onDateChange={setSelectedDates}
                  />
                  <MenuItem
                    borderRadius={"10px"}
                    display={"flex"}
                    justifyContent={"center"}
                    bgColor={"green.400"}
                    _hover={{ background: "green.600" }}
                    fontWeight={"bold"}
                    onClick={() => handleAnnual()}
                  >
                    Kaydet
                  </MenuItem>
                </Flex>
              </MenuList>
            </Menu> */}
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text onClick={signOut} fontSize="sm">
                Çıkış yap
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
