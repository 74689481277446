import { create } from 'zustand'

const useUserStore = create((set) => {
  const storedUser = JSON.parse(localStorage.getItem('user')) || {
    id: '',
    name: '',
    email: '',
    role: '',
  };

  return {
    user: storedUser,
    setUser: (user) => {
      set(() => ({ user }));
      localStorage.setItem('user', JSON.stringify(user));
    },
    removeUser: () => {
      set({
        user: {
          id: '',
          name: '',
          email: '',
          role: '',
        },
      });
      localStorage.removeItem('user');
    },
  };
});

export {useUserStore};