import { Box, SimpleGrid } from "@chakra-ui/react";
import EmployeeTable from "views/admin/employeeManagement/components/EmployeeTable";
import React from "react";
import { getEmployees } from "services/branchManager";
import { getAllEmployees } from "services/admin";
import { useUserStore } from "stores/userStore";

export default function EmployeeManagement() {
  const [employeeData,setEmployeeData] = React.useState(null);
  const user = useUserStore((state) => state.user)
  React.useEffect(() =>{
    fetchEmployees();
  } , [])


  const fetchEmployees = async (branchIds,role,status=['ACTIVE']) =>{
    const response = user.role == 'ADMIN' || user.role == 'HR' ? await getAllEmployees(branchIds,role,status) : await getEmployees(branchIds,role,status);
    setEmployeeData(response.data.map(employee => ({...employee,isOptionsEnabled:true})));
  }
  const columnsDataComplex = [
      {
        Header: "İsim",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Adres",
        accessor: "address",
      },
      {
        Header: "Telefon Numarası",
        accessor: "phoneNumber",
      },
      {
        Header:"Şube",
        accessor: "branch"
      },
      {
        Header:"Ünvan",
        accessor:"role"
      },
      {
        Header:"Çalışan Statü",
        accessor:"status"
      },
      // {
      //   Header:"İşlemler",
      //   accessor:"isOptionsEnabled"
      // }
    ];
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {employeeData &&<SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 , lg : 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <EmployeeTable
          fetchEmployees={fetchEmployees}
          columnsData={columnsDataComplex}
          tableData={employeeData}
        />
      </SimpleGrid>}
    </Box>
  );
}
