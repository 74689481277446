import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import CitiesMenu from "components/menu/CitiesMenu";
import { Toaster } from "react-hot-toast";
import { useUserStore } from '../../../../stores/userStore';


//modal
import AddBranchModal from "views/admin/default/components/AddBranchModal";
import { getCities } from "services/admin";
import Pagination from "components/Pagination";

export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  
  data.sort((a,b)=> b.branches.length - a.branches.length)
  const user = useUserStore((state) => state.user);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

 
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state: { pageIndex },
    gotoPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 8;
 
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  //modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleBranchModal() {
    onOpen();
  }

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Şehir / Şube Listesi
        </Text>
        {
          user.role == 'ADMIN'  ? 
          <Flex alignItems='baseline'>
            <CitiesMenu />
            <Button
            onClick={handleBranchModal}
            marginTop="-4px"
            marginLeft="4px"
            fontWeight="normal"
            align="center"
            justifyContent="center"
            h="37px"
            p="8px"
            lineHeight="100%"
            borderRadius="10px"
            >
              Şube Ekle
            </Button>
          </Flex> 
        :
        null
        }
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    textTransform="capitalize"
                    align="center"
                    textAlign="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "Id") {
                    data = (
                      <Flex align="center">
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Şehir") {
                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Aktif Şube Sayısı") {
                    data = (
                      <Accordion allowToggle color={textColor} border="hidden">
                        <AccordionItem border="hidden">
                          <h2>
                            <AccordionButton>
                              <Box as="span" flex="1" textAlign="left">
                                {cell.value.length}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            {cell.value.map((branch, index) => (
                              <React.Fragment key={index}>
                                {branch.name}
                                {index < cell.value.length - 1 && <br />}
                              </React.Fragment>
                            ))}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "200px" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Toaster position="top-right" />
      <Pagination
        variant='sm'
        gotoPage={gotoPage}
        pageIndex={pageIndex}
        pageCount={pageCount}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
      />
      <AddBranchModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </Card>
  );
}
