import { Button , Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody} from '@chakra-ui/react'
import React from 'react'
import {deleteTaskComment} from "services/admin";


export default function ConfirmModal({isOpen,handleCloseModal,commentId,fetchTask}) {
  async function handleCommentDelete(){
    console.log("asd");
    let response = await deleteTaskComment(commentId);
    if(response.status == 200) {
      fetchTask();
      handleCloseModal();
    }
  }
  return (
    <Modal size="sm" isOpen={isOpen} onClose={handleCloseModal} isCentered backdropFilter='blur(10px) hue-rotate(90deg)'>
    <ModalOverlay />
    <ModalContent>
    <ModalHeader>Emin Misiniz ?</ModalHeader>
      <ModalCloseButton />
      <ModalBody height='auto'>
        <Button marginRight={12} onClick={() => handleCommentDelete(commentId)}>Evet</Button>
        <Button onClick={() => handleCloseModal()}>Hayır</Button>
      </ModalBody>
    </ModalContent>
  </Modal>
  )
}
