import { Box, SimpleGrid } from "@chakra-ui/react";
// import EmployeeTable from "views/admin/employeeManagement/components/EmployeeTable";
import React, { useState } from "react";
import BannerTable from "./components/BannerTable";
import { getBanners } from "services/banner";



export default function AnnualManagement() {

 
  const [bannerData,setBannerData] = React.useState(null);
  const fetchBanners = async () => {
    let response = await getBanners();
    response = response.map(resp => ({...resp,options:{id:resp.id,status:resp.status}}));
    setBannerData(response);
  }

  React.useEffect(() =>{
    fetchBanners();
  } , [])
 
  const bannerColumnsData = [
      {
        Header: "Görsel",
        accessor: "url",
      },
      {
        Header:"Durum",
        accessor:"status"
      },
      {
        Header:"İşlemler",
        accessor: "options"
      },
    ];
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {bannerData &&<SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 , lg : 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <BannerTable
          columnsData={bannerColumnsData}
          tableData={bannerData}
          fetchBanners={fetchBanners}
        />
      </SimpleGrid>}
    </Box>
  );
}
