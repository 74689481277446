import toast from "react-hot-toast";
import axiosInstance from "./interceptors";

async function getEmployees(param,role,status) {
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.get(process.env.REACT_APP_API_EMPLOYEE_URL + `/api/employee`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function createEmployee(params) {
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.post(process.env.REACT_APP_API_EMPLOYEE_URL + '/api/employee/create',params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    if(error.response.data.code == 1002){
      toast.error("Eklemek istediğiniz personel mevcut.")
    }
    console.log(error.response.data.code);
    return error;

  }
}

export { getEmployees , createEmployee }