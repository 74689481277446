import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import toast from 'react-hot-toast';

class WebSocketService {
  constructor(socketUrl) {
    this.socketUrl = socketUrl;
    this.stompClient = null;
    this.connected = false; // Track connection state
    this.user =  JSON.parse(localStorage.getItem('user'))
  }

  connect(callback) {
    if (this.connected) {
      console.log('Already connected');
      return;
    }

    const sockjs = new SockJS(this.socketUrl);
    this.stompClient = Stomp.over(sockjs);
    this.stompClient.connect({}, (frame) => {
      this.connected = true; // Set connection state to true
      this.stompClient.subscribe(`/queue/updates/${this.user.id}`, (message) => {
        const notification = JSON.parse(message.body);
        if (callback) {
          callback(notification);
        }
        toast(notification.content, {
          icon: 'ℹ️',
        });
      });
    }, (error) => {
      console.error('Connection error:', error);
    });
  }

  disconnect() {
    if (this.stompClient !== null && this.connected) {
      this.stompClient.disconnect(() => {
        console.log('Disconnected');
        this.connected = false; // Set connection state to false
      });
    } else {
      console.log('Already disconnected');
    }
  }

  isConnected() {
    return this.connected;
  }
}

const webSocketService = new WebSocketService(process.env.REACT_APP_SOCKET_URL);
export default webSocketService;
