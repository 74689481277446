import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Image,
  Button,
  Input,
  useColorModeValue,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Box

} from "@chakra-ui/react";

import React, { useMemo, useState , useRef } from "react";
import { MdDeleteForever } from "react-icons/md";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";


// Custom components
import Card from "components/card/Card";

import Pagination from "components/Pagination";
import toast, { Toaster } from 'react-hot-toast';
import { statusEnum } from "enums";
import { deleteBannerById,createBanner,activeBannerById } from "services/banner";


// Assets

export default function BannerTable(props) {
  const { columnsData, tableData, fetchBanners} = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [src,setSrc] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  const handlePreviewModal = (src) => {
    setSrc(src);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };
  
  const deleteById = async(id) =>{
    const areYouSure = window.confirm('Silmek İstediğinize Emin Misiniz ?')
    if(areYouSure){
      let response = await deleteBannerById(id);
      fetchBanners();
  
    }
  }

  const activateById = async(id) =>{
    const areYouSure = window.confirm('Aktif Etmek İstediğinize Emin Misiniz ?')
    if(areYouSure){
      let response = await activeBannerById(id);
      if(response.status == 200){
        toast.success('Görsel Başarıyla Aktif Edildi')
        fetchBanners();
      }else{
        toast.error('Sistemsel Bir Hata Oluştu')
      }
    }
  
  }

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    let response = await createBanner(file);
    if(response.status == 200){
      toast.success('Görsel Başarıyla Yüklendi');
      fetchBanners();
    }
    else if(response.response.data.code == 100001){
      toast.error('Desteklenmeyen Dosya Türü')
    }
    else{
      toast.error('Sistemsel Bir Hata Oluştu')
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    gotoPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    initialState,
  } = tableInstance;
  initialState.pageSize = 8;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const passiveColor = useColorModeValue("secondaryGray.900", "gray");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <>
      <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
          <Text
            color={textColor}
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'>
            Görseller
          </Text>
          <Box>
            <Input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              display="none" // Hide the actual file input element
            />
            <Button onClick={handleClick}>Görsel Ekle</Button>
          </Box>
        </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      textTransform='capitalize'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}  >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Görsel") {
                      data = (
                        <Box cursor='pointer' onClick={() => handlePreviewModal(cell.value)}>
                          <Image src={cell.value} maxW={{ sm: "50px", md: "200px", lg: "200px" }} width={'200px'} height={'100px'} />
                        </Box>
                      );
                    }else if (cell.column.Header == 'Durum'){
                      data = (
                        <Flex><Text color={textColor} 
                        fontSize="16px"
                        fontWeight="700"
                        lineHeight="100%">{statusEnum[cell.value]}</Text></Flex>
                      )
                    }else if (cell.column.Header == 'İşlemler'){
                      data = (
                        <Flex gap='8px' alignItems='center'>
                          <Button disabled={cell.value.status == 'ACTIVE'}  onClick={() => activateById(cell.value.id)}  color={cell.value.status == 'ACTIVE' ? passiveColor : textColor}>{cell.value.status == 'ACTIVE' ? 'Aktif' : 'Aktif Et'}</Button>
                          <MdDeleteForever cursor='pointer' color={textColor} size='25px' onClick={() => deleteById(cell.value.id)}/>
                        </Flex>
                      )
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "50px", md: "200px", lg: "200px" }}
                        >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Toaster position="top-right" />
        <Pagination gotoPage={gotoPage} pageIndex={pageIndex} pageCount={pageCount} canPreviousPage={canPreviousPage} canNextPage={canNextPage} />
      </Card>
      <Modal size="6xl" isOpen={isOpen} onClose={handleCloseModal} isCentered backdropFilter='blur(10px) hue-rotate(90deg)'>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>Önizleme</ModalHeader>
          <ModalCloseButton />
          <ModalBody height='auto'>
            <Image src={src} maxW="100%" height="auto" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
