import { create } from 'zustand'
import { updateTaskStatus } from 'services/admin';

const useTaskStore = create((set) => {
  const storedTask = JSON.parse(sessionStorage.getItem('selectedTask')) || null;

  return {
    task: storedTask,
    setTask: (task) => {
      sessionStorage.setItem('selectedTask',JSON.stringify(task));
      set(() => ({ task }));
    },
    updateTaskStatus:async (params) => {
      let response = await updateTaskStatus(params);
      return response;
    }
  };
});

export {useTaskStore};