import { create } from 'zustand'

const useCityStore = create((set) => {
  const storedCity = {
    cities:[]
  };

  return {
    city: storedCity,
    setCity: (city) => {
      set(() => ({ city }));
    },
  };
});

export {useCityStore};