export const titleEnum = {
  ADMIN: 'Admin',
  BRANCH_MANAGER: 'Şube Müdürü',
  FINANCE: 'Finans',
  PURCHASING: 'Satın Alma',
  FACTORY_MANAGER: 'Fabrika Müdürü',
  HR: 'İnsan Kaynakları',
  BRAND_MANAGER: 'Marka Yöneticisi',
  COORDINATOR: 'Koordinatör',
  MARKETING: 'Pazarlama',
  PRODUCTION: 'Üretim',
  LOGISTICS: 'Lojistik',
  FRANCHISOR: 'Franchiser',
  SUPERVISOR: 'Supervisor',
  SUPERVISOR_BUDDY: 'Supervisor Buddy',
  BARISTA: 'Barista',
  BAR_BUDDY: 'Bar Buddy',
  INTERN: 'Stajyer'
}
export const roleEnum = [
  {
    value:"ADMIN",
    name:"Admin"
  },
  {
    value:"BRANCH_MANAGER",
    name:"Şube Müdürü"
  },
  {
    value:"FINANCE",
    name:"Finans"
  },
  {
    value:"PURCHASING",
    name:"Satın Alma"
  },
  {
    value:"FACTORY_MANAGER",
    name:"Fabrika Müdürü"
  },
  {
    value:"HR",
    name:"İnsan Kaynakları"
  },
  {
    value:"BRAND_MANAGER",
    name:"Marka Yöneticisi"
  },
  {
    value:"COORDINATOR",
    name:"Koordinatör"
  },
  {
    value:"MARKETING",
    name:"Pazarlama"
  },
  {
    value:"PRODUCTION",
    name:"Üretim"
  },
  {
    value:"LOGISTICS",
    name:"Lojistik"
  },
  {
    value:"FRANCHISOR",
    name:"Franchiser"
  },
  {
    value:"SUPERVISOR",
    name:"Supervisor"
  },
  {
    value:"SUPERVISOR_BUDDY",
    name:"Supervisor Buddy"
  },
  {
    value:"BARISTA",
    name:"Barista"
  },
  {
    value:"BAR_BUDDY",
    name:"Bar Buddy"
  },
  {
    value:"INTERN",
    name:"Stajyer"
  }
]
export const statusEnum = {
  ACTIVE: 'Aktif',
  PASSIVE: 'Pasif',
  PASSWORD_CHANGE_REQUIRED: 'Şifre Blokeli'
}



export const typeEnum = {
  PRODUCT: 'Ürün',
  RAW: "Hammadde"
}
export const subTypeEnum = {
  PRODUCT: 'Ürün',
  RAW: "Hammadde"
}

export const taskStatusEnum = {
  CREATED : 'Oluşturuldu',
  ONGOING : 'Devam Ediyor',
  COMPLETED: 'Tamamlandı',
  CANCELED: 'İptal Edildi'
}
export const productStatusEnum = {
  ACTIVE: 'Aktif',
  PASSIVE: 'Pasif'
}
export const productTypeEnum = {
  DRY : 'Kuru',
  PLUS4 : '+4',
  MINUS18 : '-18',
  SPARE_PART : 'Yedek Parça',
}
export const orderStatusEnum = {
  CREATED : 'Oluşturuldu',
  FINANCE_APPROVE : 'Finans Onayında',
  SHIPMENT_APPROVE : 'Kargo Onayında',
  SHIPPING : 'Kargoda',
  DELIVERED : 'Teslim Edildi',
  REJECTED : 'Reddedildi',
 
}
export const shippingTypeEnum = {
  DRY : 'Kuru',
  MINUS_18 : '-18',
  PLUS_4 : '+4',
}
export const shippingStatusEnum = {
  SHIPPING : 'Yolda',
  DELIVERED : 'Teslim edidi',
  DENIED : 'Reddedildi',
}

export const annualEnum ={
  CREATED : 'Oluşturuldu',
  ACCEPTED : 'Kabul Edildi',
  DECLINED: 'Reddedildi',
}

export const annualType = {
  ANNUAL:'Yıllık',
  UNPAID:'Ücretsiz İzin',
  MEDICAL:'Raporlu'
}