import React from "react";
import { turkishCities } from "utils";
import { addCity } from "services/admin";

// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";
// Assets
import {
  MdLocationCity,
} from "react-icons/md";
import { getCities } from "services/admin";
import { useCityStore } from "stores/cityStore";

export default function Banner(props) {
  const { ...rest } = props;

  const textColor = useColorModeValue("secondaryGray.500", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  
  const updateCity = useCityStore(store => store.setCity)
  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const [selectedCity, setSelectedCity] = React.useState(null);

  const handleCityAdd = async () => {
    await addCity(selectedCity);
    let response = await getCities();
    updateCity({ cities: response.data.sort((a, b) => a.name.localeCompare(b.name)) });
    onConfirmClose();
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    onConfirmOpen();
  };

  // const handleCityAdd = async (city) => {
  //   await addCity(city);
  //   let response = await getCities();
  //   updateCity({cities:response.data.sort((a,b) => a.name.localeCompare(b.name))})
  // }

  return (
    <>
    <Menu closeOnSelect isOpen={isOpen1} onClose={onClose1}>
      <MenuButton
        whiteSpace="nowrap"
        align='center'
        justifyContent='center'
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        h='37px'
        p='8px'
        lineHeight='100%'
        onClick={onOpen1}
        borderRadius='10px'
        {...rest}>
          Şehir Ekle
      </MenuButton>
      <MenuList
        height='250px'
        overflow='scroll'
        zIndex='3'
        w='150px'
        minW='unset'
        maxW='150px !important'
        border='transparent'
        backdropFilter='blur(63px)'
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius='20px'
        p='8px'>
          {
            turkishCities.map((city,i) => (
              <MenuItem 
              key={i}
              onClick={() => handleCitySelect(city)}
              transition='0.2s linear'
              color={textColor}
              _hover={textHover}
              p='0px'
              borderRadius='8px'
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb='10px'>
              <Flex align='center'>
                <Icon as={MdLocationCity} h='16px' w='16px' me='8px' />
                <Text fontSize='sm' fontWeight='400'>
                  {city}
                </Text>
              </Flex>
            </MenuItem>
            ))
          }
      </MenuList>
    </Menu>
          <Modal isOpen={isConfirmOpen} onClose={onConfirmClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Şehir Ekle</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              "{selectedCity}" şehrini eklemek istediğinize emin misiniz ?
            </ModalBody>
  
            <ModalFooter>
              <Button variant="ghost" onClick={onConfirmClose}>İptal</Button>
              <Button colorScheme="blue" onClick={handleCityAdd} ml={3}>
                Evet
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    </>
  );
}
