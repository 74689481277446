import axiosInstance from "./interceptors";
import axios from "axios";

import toast from "react-hot-toast";


async function handleLogin(params) {
  const { email,password } = params;

  try {
    // Make a POST request to your server's login endpoint
    const response = await axios.post(process.env.REACT_APP_API_USER_URL + '/api/auth/login', {
      email: email,
      password: password
    });

    // Assuming the server responds with a token upon successful login
    const token = response.token;

    // Store the token in localStorage or a secure cookie for future requests
    localStorage.setItem('token', token);
    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    if(error.response.data && error.response.data.code == 1013){
      toast.error('Şifreniz bloke olmuştur şifre sıfırlamalısınız.')
    }else if(error.response.data && error.response.data.code == 9999) toast.error('Email veya şifreniz yanlıştır.')
    return error;

  }
}

async function handlePasswordReset(params) {
  const { resetPasswordCode,newPassword,email } = params;

  try {
    const response = await axios.post(process.env.REACT_APP_API_USER_URL + '/api/password/reset-password?resetPasswordCode=' + resetPasswordCode + '&newPassword='+newPassword + '&email='+ email);

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    console.error('Login failed:', error);
    return error;

  }
}

async function handlePasswordResetMail(email) {

  try {
    const response = await axios.post(process.env.REACT_APP_API_USER_URL + '/api/password/send-reset-password-url?email=' + email);

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    console.error('Login failed:', error.message);
    return error;

  }
}

async function handleLogout() {
  const token = localStorage.getItem('token');
  try {
    // Make a POST request to your server's login endpoint
    const response = await axios.delete(process.env.REACT_APP_API_USER_URL + '/api/auth/logout',{
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    localStorage.removeItem("token");

    // Assuming the server responds with a token upon successful login

    // Store the token in localStorage or a secure cookie for future requests
    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    console.error('Login failed:', error.message);
    return error;

  }
}

async function getCities() {
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.get(process.env.REACT_APP_API_USER_URL + '/api/admin/city', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function addCity(city){
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.post(process.env.REACT_APP_API_USER_URL + `/api/admin/create-city?name=${city}`,{} ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if(response.status == 200){
      toast.success("Şehir başarıyla eklendi.")
    }

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    if(error.response.data.code == 1010){
      toast.error("Eklemek istediğiniz şehir mevcut.")
    }
    console.log(error.response.data.code);
    return error;

  }
} 

async function addBranch(params){
  const token = localStorage.getItem('token');
  const {cityName,branchName} = params;
  try {
    const response = await axiosInstance.post(process.env.REACT_APP_API_USER_URL + `/api/admin/create-branch?cityName=${cityName}&branchName=${branchName}`,{} ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if(response.status == 200){
      toast.success("Şube başarıyla eklendi.")
    }

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    if(error.response.data.code == 1004){
      toast.error("Eklemek istediğiniz şube mevcut.")
    }
    console.log(error.response.data.code);
    return error;

  }
} 

async function getAllEmployees(param,role,status) {
  const token = localStorage.getItem('token');
  let params = {
    branchIds: role == 'ADMIN' && !param?.length ? null : param,
    userStatus: status?.length > 1 ? 'PASSIVE' : null
    // userStatus: ["ACTIVE", "PASSIVE"]
  };
  const setParam = () => {
    if(params.userStatus && (params.branchIds && !params.branchIds.length)) return `?${params.userStatus ? `&status=${params.userStatus}` : ''}`;
    if(!param || !param.length) return "";
    return `?branchIds=${param ? params.branchIds : null}${params.userStatus ? `&status=${params.userStatus}` : ''}`;
  }
  try {
    const response = await axiosInstance.get(process.env.REACT_APP_API_EMPLOYEE_URL + `/api/employee/all${setParam()}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function fetchBranchInventory(params) {
  const {branchId} = params;
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.get(process.env.REACT_APP_API_URL + '/api/inventory/branch?branchId=' + branchId, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    console.log(error);
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function fetchBranchManagerInventory() {
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.get(process.env.REACT_APP_API_URL + '/api/inventory/branch', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    console.log(error);
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function getTasks({userId='',statusList=["CREATED","COMPLETED","CANCELED","ONGOING"],startDate,endDate}) {

  const token = localStorage.getItem('token');
  
  let statusApi = ''
  statusList.forEach(status => {
    statusApi += ('&statusList=' + status)
  });
  
  const api = process.env.REACT_APP_API_TASK_URL +'/api/task' + '?startDate='+startDate + '&endDate=' + endDate  + statusApi + (userId == '' ? '' :('&userId=' + userId ))
  
  try {
    const response = await axiosInstance.get(api, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    console.log(error);
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function createTask(params) {
  const token = localStorage.getItem('token');
  let queryParams = new URLSearchParams({
    description:params.description,
    assigneeIdList:params.assigneeIdList,
    dueDate:params.dueDate,
    title:params.title,
  })
  let formData = new FormData();
  if(params.files) {
    for (var i = 0; i < params.files.length; i++) {
        var file = params.files[i];
        formData.append('files',file)
    }
  }
  try {
    const response = await axiosInstance.post(process.env.REACT_APP_API_TASK_URL + '/api/task/create?' + queryParams.toString(),formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function getTaskById(param){
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.get(process.env.REACT_APP_API_TASK_URL + '/api/task/'+param, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }
    return response;
    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function postTaskComment(params,file) {
  const token = localStorage.getItem('token');
  const {taskId,comment} = params;
  let formData = new FormData();
  if(file) {
    console.log('a');
    for (let i = 0; i < file.length; i++) {
        let image = file[i];
        console.log(image);
        formData.append('files',image)
    }
  }
  console.log(file);
  console.log(formData.files);
  try {
    const response = await axiosInstance.post(process.env.REACT_APP_API_TASK_URL + `/api/task/comment?taskId=${taskId}&comment=${comment}`,formData,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }
    if(response.status == 200) {
      toast.success("Yorumunuz başarıyla eklendi.")
    }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    if(error.response.data.code == 100001){
      toast.error("Desteklenmeyen Dosya Türü");
    }
    return error;

  }
}

async function updateTaskStatus(params) {
  const token = localStorage.getItem('token');
  const {status,taskId} = params;
  try {
    const response = await axiosInstance.patch(process.env.REACT_APP_API_TASK_URL + `/api/task/update?taskId=${taskId}&status=${status}${params.rejectionComment ? `&rejectionComment=${params.rejectionComment}` : ''}`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      window.reactHistory.push('/auth/sign-in');
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function getHeadOfficeEmployees() {
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.get(process.env.REACT_APP_API_EMPLOYEE_URL + '/api/employee', {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;
    

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    console.log(error);
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function registerEmployee(params) {
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.post(process.env.REACT_APP_API_USER_URL + '/api/auth/register',params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    if(error.response.data.code == 1002){
      toast.error("Eklemek istediğiniz personel mevcut.")
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function updateTaskContent(params) {

  const token = localStorage.getItem('token');
  const {taskId} = params;
  let formData = new FormData();
  if(params.files) {
    for (var i = 0; i < params.files.length; i++) {
        var file = params.files[i];
        formData.append('files',file)
    }
  }

  try {
    const response = await axiosInstance.put(process.env.REACT_APP_API_TASK_URL + '/api/task/add-content?' + 'taskId=' + taskId,formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }
    if(response.status == 200){
      toast.success('Dosya Başarıyla Yüklendi')
    }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    if(error.response.data.code == 100001){
      toast.error("Desteklenmeyen Dosya Türü");
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function handleTaskContentDelete(contentId) {
  const token = localStorage.getItem('token');
  try {
    // Make a POST request to your server's login endpoint
    const response = await axiosInstance.delete(process.env.REACT_APP_API_TASK_URL + `/api/task/content?contentId=${contentId}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    if(response.status == 200){
      toast.success('Dosya başarıyla silindi.')
    }

    return response;
   
  } catch (error) {
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    return error;

  }
}

async function updateTaskDescription(params) {
  const token = localStorage.getItem('token');
  const {description,taskId} = params;
  try {
    const response = await axiosInstance.patch(process.env.REACT_APP_API_TASK_URL + `/api/task/${taskId}?description=${description}`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    if(response.status == 200){
      toast.success("Görev Açıklaması Başarıyla Güncellendi")
    }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function deleteTaskComment(commentId) {
  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.delete(process.env.REACT_APP_API_TASK_URL + `/api/task/${commentId}`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    if(response.status == 200){
      toast.success("Yorum Başarıyla Silindi")
    }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    return error;

  }
}

export { handleLogin , getCities , getAllEmployees , handleLogout, addCity , addBranch , handlePasswordReset, 
  handlePasswordResetMail,fetchBranchInventory,fetchBranchManagerInventory, getTasks , getHeadOfficeEmployees,
  createTask,postTaskComment,updateTaskStatus,registerEmployee,getTaskById,updateTaskContent,handleTaskContentDelete,updateTaskDescription,deleteTaskComment}