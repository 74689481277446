export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
export const dashboardCityColumns = [
  {
    Header: "Şehir",
    accessor: "name",
  },
  {
    Header: "Aktif Şube Sayısı",
    accessor: "branches",
  },
];
export const dashboardEmployeeColumns = [
  {
    Header: "İsim",
    accessor: "name",
  },
  {
    Header: "Telefon No",
    accessor: "phoneNumber",
  },
  {
    Header: "Şubesi",
    accessor: "branch",
  },
  {
    Header: "Ünvan",
    accessor: "role",
  },
  {
    Header: "Çalışan Statü",
    accessor: "status",
  },
];
