import { Box, SimpleGrid } from "@chakra-ui/react";
// import EmployeeTable from "views/admin/employeeManagement/components/EmployeeTable";
import TaskTable from 'views/admin/taskManagement/components/TaskTable'
import React, { useState } from "react";
import { getTasks,getHeadOfficeEmployees } from "services/admin";
import { format } from 'date-fns';
import { useUserStore } from "stores/userStore";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export default function EmployeeManagement() {
  let history = useHistory();
  if (!window.reactHistory) {
    window.reactHistory = history;
  // attach history object to window object. 
  }
  const [taskData,setTaskData] = useState(null)
  const [employeeData,setEmployeeData] = React.useState(null);
  const user = useUserStore(state => state.user);

  React.useEffect(() =>{
    if(user.role == 'ADMIN' || user.role == 'BRANCH_MANAGER' || user.role == 'FINANCE' || user.role == 'WAREHOUSE_MANAGER' || user.role == 'HR' || user.role == 'TECHNIC') fetchEmployees();
    fetchTask({statusList:["CREATED","COMPLETED","CANCELED","ONGOING"],startDate:format(aMonthAgo,'yyyy-MM-dd'),endDate:format(aMonthLater,'yyyy-MM-dd')})
  } , [])


  const fetchEmployees = async () =>{
    const response = await getHeadOfficeEmployees();
    setEmployeeData(response.data.map(employee => ({...employee})));
  }
  

  
 
  const aMonthAgo = new Date()
  const aMonthLater = new Date()
  aMonthAgo.setMonth(aMonthAgo.getMonth() - 1)
  aMonthLater.setMonth(aMonthLater.getMonth() + 1)


  const fetchTask = async (userId,statusList,startDate,endDate) =>{
    const response = await getTasks(userId,statusList,startDate,endDate)
    setTaskData(response.data.map(task => ({...task,isOptionsEnabled:true})));
  }
  const columnsDataComplex = [
      {
        Header: "Atanan",
        accessor: "assigneName",
      },
      {
        Header:"Atayan",
        accessor:"assignorName"
      },
      {
        Header: "Başlık",
        accessor: "title",
      },
      {
        Header: "Bitiş Tarihi",
        accessor: "dueDate",
      },
      {
        Header:"Durum",
        accessor: "status"
      },
      {
        Header:"Detay",
        accessor: "isOptionsEnabled"
      },
    ];
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {taskData &&<SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 , lg : 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <TaskTable
          fetchTask={fetchTask}
          columnsData={columnsDataComplex}
          tableData={taskData}
          employeeData={employeeData}
        />
      </SimpleGrid>}
    </Box>
  );
}
