import toast from "react-hot-toast";
import axiosInstance from "./interceptors";

async function createBanner(file) {
  const token = localStorage.getItem('token');
  let formData = new FormData();
  if(file) {
    formData.append('file',file)
  }
  try {
    const response = await axiosInstance.post(process.env.REACT_APP_API_BANNER_URL + '/api/banner', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function getBanners() {


  const api = process.env.REACT_APP_API_BANNER_URL + '/api/banner';

  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.get(api, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;

    // Redirect or perform other actions after successful login
  } catch (error) {
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }

}


async function deleteBannerById(id) {

  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.delete(process.env.REACT_APP_API_BANNER_URL + `/api/banner?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}

async function activeBannerById(id) {

  const token = localStorage.getItem('token');
  try {
    const response = await axiosInstance.patch(process.env.REACT_APP_API_BANNER_URL + `/api/banner?id=${id}`,{}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // if(error.response.request.status == 401 && window.reactHistory){
    //   window.reactHistory.push('auth/sign-in');
    // }

    return response;

    // Redirect or perform other actions after successful login
  } catch (error) {
    console.log(error);
    // Handle login failure
    if(error.response.data.code == 9999 && window.reactHistory){
      toast.error('Sistemsel Hata Oluştu.')
    }
    console.log(error.response.data.code);
    return error;

  }
}



export {
  getBanners,
  deleteBannerById,
  createBanner,
  activeBannerById
}