import React, { useEffect, useState } from 'react'
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  Button,
  Select,
  FormLabel
} from "@chakra-ui/react";
import { useCityStore } from 'stores/cityStore';
import { getCities } from 'services/admin';
import InputField from "components/fields/InputField";
import { addBranch } from 'services/admin';
import { useBranchStore } from 'stores/branchStore';
import toast from "react-hot-toast";


export default function AddBranchModal({ isOpen, onClose, onOpen }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const updateCity = useCityStore(store => store.setCity)
  const branches = useBranchStore((state) => state.branches);
  // const { isOpen,onOpen, onClose } = useDisclosure();

  const cityData = useCityStore((state) => state.city)

  const [formData, setFormData] = useState({
    cityName: 'Bir şehir seçin',
    branchName: ''
  })

  function onChangeHandler(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const handleBranchAdd = async (formData) => {
    const city = cityData.cities.find(e => e.name ==formData.cityName)
    const find = city.branches.find(e => e.name == formData.branchName)
    if(!find){
      await addBranch(formData);
      let response = await getCities();
      updateCity({cities:response.data.sort((a,b) => a.name.localeCompare(b.name))})
    }
    else{
      toast.error('Bu şehirde aynı isimle başka bir şube mecvut')
    }
    
  }
  

  function submitForm() {
    if (formData.cityName !== '' && formData.branchName !== '') {
      handleBranchAdd(formData)
      onClose()
      setFormData({
        cityName: 'Bir şehir seçin',
        branchName: ''
      })
    }

  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered backdropFilter='blur(10px) hue-rotate(90deg)'>
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader bg={borderColor}>{userData.name ? userData.name + 'Düzenle' : 'Çalışan Ekle'}</ModalHeader> */}
        <ModalHeader bg={borderColor}>Şube Ekle/Düzenle</ModalHeader>
        <ModalCloseButton />
        <ModalBody bg={borderColor}>
          <FormLabel ms={2} fontWeight={'bold'} >Şehir</FormLabel>
          <Select className="city-select-wrapper"  mb={2} required name="cityName" value={formData.cityName} onChange={(e) => onChangeHandler(e)} >
            <option disabled>Bir şehir seçin</option>
            {
              cityData.cities.length > 0 && cityData.cities.map((city, index) => (
                <option key={index}>{city.name}</option>
              ))
            }
          </Select>
          <InputField required name="branchName" label="Şube" type="text" value={formData.branchName} onChange={(e) => onChangeHandler(e)} />
        </ModalBody>
        <ModalFooter bg={borderColor}>
          <Button colorScheme="green" mr={3} onClick={submitForm} disabled={formData.cityName == 'Bir şehir seçin'}>
            Kaydet
          </Button>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Kapat
          </Button>
          {/* Add other modal actions as needed */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
