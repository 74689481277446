import { Button , Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody} from '@chakra-ui/react'
import React from 'react'
import {handleTaskContentDelete} from "services/admin";


export default function ContentModal({isOpen,handleCloseModal,contentId,fetchTask}) {
  const handleFileDelete = async (id) => {
    let response = await handleTaskContentDelete(id);
    if(response.status == 200){
      fetchTask();
      handleCloseModal();
    }
  }
  return (
    <Modal size="sm" isOpen={isOpen} onClose={handleCloseModal} isCentered backdropFilter='blur(10px) hue-rotate(90deg)'>
    <ModalOverlay />
    <ModalContent>
    <ModalHeader>Emin Misiniz ?</ModalHeader>
      <ModalCloseButton />
      <ModalBody height='auto'>
        <Button marginRight={12} onClick={() => handleFileDelete(contentId)}>Evet</Button>
        <Button onClick={() => handleCloseModal()}>Hayır</Button>
      </ModalBody>
    </ModalContent>
  </Modal>
  )
}
