import { Button , Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody} from '@chakra-ui/react'
import React from 'react';
import InputField from "components/fields/InputField";
import toast from 'react-hot-toast';


export default function RejectionModal({isOpen,handleCloseModal,updateStatus}) {
  const [rejectionComment,setRejectionComment] = React.useState("");
  const onInputChange = (e) => {
    setRejectionComment(e.target.value);
  }
  const updateFunc = () => {
    if(rejectionComment.length <= 0){
      toast.error("Lütfen İptal Notu Giriniz !") 
      return;
    }
    updateStatus(rejectionComment);
    handleCloseModal();
  }
  return (
    <Modal size="sm" isOpen={isOpen} onClose={handleCloseModal} isCentered backdropFilter='blur(10px) hue-rotate(90deg)'>
    <ModalOverlay />
    <ModalContent>
    <ModalHeader>İptal Notu Giriniz</ModalHeader>
      <ModalCloseButton />
      <ModalBody height='auto'>
        <InputField required name="rejectionComment" label="İptal Notu" value={rejectionComment} onChange={onInputChange} />
        <Button marginRight={12} onClick={() => updateFunc()}>Yolla</Button>
      </ModalBody>
    </ModalContent>
  </Modal>
  )
}
